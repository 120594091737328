import _ from "lodash";
import { invokeAppSync } from "../libs/authLib";
import { getApiPrefix } from "../libs/apiLib";
import config from "../config";
import { ITimeRange } from "../interfaces/Device/ITimeRange";
import { IPlantSearchResult } from "../interfaces/Device/IPlantSearchResult";
import { ISnapshotFilter } from "../interfaces/AppGlobal/ISnapshotFilter";
import Utils from "../utilities/utils";
import { EventReverseTypeMap } from "../constants/global";
import { IEventHistoryFilter } from "../interfaces/AppGlobal/IEventHistoryFilter";
import moment from "moment-timezone";
import { ISupportRequestObj } from "../interfaces/DeviceInfo/ISupportRequestObj";
import { escapeCharacters } from "../utilities/stringutils";
import { IServiceLogInput } from "../interfaces/IServiceLog";
import { extractErrors } from "../libs/getDynoErrors";

const baseAppSyncUrl = () => getApiPrefix();

const ignoreEmpty = (el) =>
  el != null && typeof el === "object" && Object.keys(el).length === 0
    ? null
    : el;
const ignoreNegative = (el) => (Number(el) < 0 ? 0 : el);
const ignoreMoreThan100 = (el) => (Number(el) > 1 ? 1 : el);
const convertPercent = (el) =>
  !isNaN(el) ? Number(Math.round(10000 * el) / 100.0) : el;
const convertStrangeChars = (el) =>
  typeof el === "string" ? _.unescape(el) : el;

const checkAppSyncUrlExists = () => {
  return !!config.appSync["VRS_APPSYNC_DEVICE_URL"];
};

const produceOperationMode = (value) => {
  if (value === "1") {
    return "1h";
  }

  if (value === "4") {
    return "4h";
  }
  if (value === "8") {
    return "8h";
  }

  if (value === "24") {
    return "24h";
  }

  if (value === "168") {
    return "7days";
  }

  if (value === "720") {
    return "30days";
  }

  if (value === "1440") {
    return "60days";
  }

  return "h";
};
class VrsDeviceApi {
  static getAppSyncDeviceCookie(deviceId: string) {
    if (!checkAppSyncUrlExists()) {
      return Promise.resolve({});
    }


    const graphQLBody = {
      query: `
          query DeviceCookieQuery {
            device(id: "${deviceId}") {
                PrinterCookie
            }
          }
        `,
    };

    return invokeAppSync({
      gatewayName: "VRS_APPSYNC_DEVICE",
      path: baseAppSyncUrl(),
      method: "POST",
      body: graphQLBody,
    });
  }

  static createEventHistoryModelInput(eventHistoryFilter: IEventHistoryFilter) {
    const eventHistoryModelInput = `
    eventHistoryModel: {
      FromTime: ${eventHistoryFilter.timeRange.value === "custom" &&
        eventHistoryFilter.timeRange.start
        ? '"' +
        Utils.evaluateAsUtc(
          moment(eventHistoryFilter.timeRange.start),
          true
        ) +
        '"'
        : null
      },
      ToTime:${eventHistoryFilter.timeRange.value === "custom" &&
        eventHistoryFilter.timeRange.end
        ? '"' +
        Utils.evaluateAsUtc(
          moment(eventHistoryFilter.timeRange.end),
          false
        ) +
        '"'
        : null
      },
      OperationMode: "${produceOperationMode(
        eventHistoryFilter.timeRange.value
      )}",
      Ascending: false,
      AutoReload: false,
      SuppressWarnings: ${eventHistoryFilter.suppressWarnings !== null
        ? eventHistoryFilter.suppressWarnings
        : null
      },
      EventTypeFilter: ${eventHistoryFilter.eventTypeFilter
        ? "[" +
        eventHistoryFilter.eventTypeFilter.map(
          (el) => EventReverseTypeMap[el]
        ) +
        "]"
        : null
      },
      EventZoomInterval: "",
      FilterEventList: "${eventHistoryFilter.filterEventList
        ? eventHistoryFilter.filterEventList
          .filter((el) => !el.selected)
          .map((el) => el.id)
          .join("|:|")
        : null
      }",
      Sort: 0
	  }`;

    return eventHistoryModelInput;
  }

  static createStatusSnapshotModelInput(snapshotFilter: ISnapshotFilter) {
    const startEndObj = Utils.getStartAndEndDate(snapshotFilter.timeRange);
    const statusSnapshotModelInput = `
    statusSnapshotModel: {
        FromTime: "${snapshotFilter.timeRange.value === "custom" &&
        snapshotFilter.timeRange.start
        ? Utils.evaluateAsUtc(moment(snapshotFilter.timeRange.start), true)
        : Utils.convertToUtc(moment(startEndObj.start))
      }",
        ToTime: "${snapshotFilter.timeRange.value === "custom" &&
        snapshotFilter.timeRange.end
        ? Utils.evaluateAsUtc(moment(snapshotFilter.timeRange.end), true)
        : Utils.convertToUtc(moment(startEndObj.end))
      }",
        EventTypeFilter: ${snapshotFilter.eventTypeFilter
        ? "[" +
        snapshotFilter.eventTypeFilter.map(
          (el) => EventReverseTypeMap[el]
        ) +
        "]"
        : null
      },
        ManualUpdate: true,
        Sort: 0,
        EventZoomTargetDate: "",
        SuppressWarnings: ${snapshotFilter.suppressWarnings}
    }`;

    return statusSnapshotModelInput;
  }

  static getAppSyncDeviceBasic(deviceId: string) {
    if (!checkAppSyncUrlExists()) {
      return Promise.resolve({});
    }

    const graphQLBody = {
      query: `
          query DeviceQueryBasic{
            device(id: "${deviceId}") {
                PrinterIpAddress
                IsCellular
                CompanyID
                CompanyName
                CurrentJob
                DurationSec
                FriendlyName
                JobCount
                ModelName
                ModelId
                ModelType           
                ModelName
                PlantID
                ImageName
                PlantName
                PrinterID
                SerialNumber
                Status
                StatusMessage
                TimeZoneString
                EnableParameterHistory
                LicenseStatus {
                  LicenseColor
                  LicenseDatesText
                  LicenseStatusText
                  licenseImageString
                }
                ConsumablesLevel {
                  Core
                  CoreLevel
                  CoreLevelPercent
                  CoreRemaining
                  InkLevel
                  LastUpdate
                  MakeupLevel
                  ServiceModule1PredictedExpirationDate
                  ServiceModuleLifetime
                  TotalJetOnTime
                }
                PrinterStatus {
                  AvailabilityStatus
                  CurrentJob
                  EnableWarningSuppression
                  HasSuppressible
                  FaultCount
                  HealthScorePercentile
                  HealthScoreStatus
                  JobCount
                  LastImage
                  LastUpdate
                  LicenseDatesText
                  LicenseStatusImageName
                  LicenseStatusText
                  PercentAvailable
                  ResId
                  ResStatusId
                  PrinterLicenseEndDate
                  PrinterLicenseStartDate
                  Second
                  StatusMessage
                  LastStatusMessages {
                    MessageName 
                    MessageDate 
                    MessageType 
                  }
                  StatusTypeID
                  VncAvailable                              
                }
                ApplicatorType
              }
            }
        `,
    };

    return invokeAppSync({
      gatewayName: "VRS_APPSYNC_DEVICE",
      path: baseAppSyncUrl(),
      method: "POST",
      body: graphQLBody,
      retryInTimeOut: true,
    });
  }

  static getAppSyncDeviceFull(
    deviceId: string,
    eventHistoryFilter: IEventHistoryFilter,
    snapshotFilter: ISnapshotFilter
  ) {
    if (!checkAppSyncUrlExists()) {
      return Promise.resolve({});
    }

    const eventHistoryModelInput =
      VrsDeviceApi.createEventHistoryModelInput(eventHistoryFilter);
    const statusSnapshotModelInput =
      VrsDeviceApi.createStatusSnapshotModelInput(snapshotFilter);


    const graphQLBody = {
      query: `
          query DeviceQuery{
            device(id: "${deviceId}", ${eventHistoryModelInput}, ${statusSnapshotModelInput}) {
                PrinterIpAddress
                PlantID
                PlantName
                PrinterID
                IsCellular
                CompanyID
                CompanyName
                FriendlyName
                JobCount
                ModelName
                ModelId
                ModelType
                ParameterHistory               
                ImageName
                PlantName
                PrinterID
                SerialNumber
                Status
                TimeZoneString
                EnableParameterHistory
                LicenseStatus {
                  LicenseColor
                  LicenseDatesText
                  LicenseStatusText
                  licenseImageString
                }
                ConsumablesLevel {
                  Core
                  CoreLevel
                  CoreLevelPercent
                  CoreRemaining
                  InkLevel
                  LastUpdate
                  MakeupLevel
                  ServiceModule1PredictedExpirationDate
                  ServiceModuleLifetime
                  TotalJetOnTime
                }
                PrinterStatus {
                  AvailabilityStatus
                  
                  CurrentJob
                  EnableWarningSuppression
                  HasSuppressible
                  FaultCount

                  HealthScorePercentile
                  HealthScoreStatus
                  JobCount
                  LastImage
                  LastUpdate
                  LicenseDatesText
                  LicenseStatusImageName
                  LicenseStatusText

                  PercentAvailable
    
                  PrinterLicenseEndDate
                  PrinterLicenseStartDate
                  Second
                  StatusMessage
                  LastStatusMessages {
                    MessageName 
                    MessageDate 
                    MessageType 
                  }
                  StatusTypeID
                  VncAvailable
                }
                CurrentJob
                DurationSec
                EventHistory {
                  EventTypeFilter
                  EventZoomTargetDate
                  Sort
                  Results {
                    EventId
                    DateTime
                    UtcDateTime
                    EventType
                    EventName
                    DateString
                    TestResult
                    EventCodeId
                    SalesForceArticleLink
                    ArticleLink
                    ArticleId
                    ActiveArticleLink
                  }
                  Stats {
                    EventType
                    EventsList {
                      EventCount
                      EventId
                      EventName
                      HasEvents
                    }
                    TypeId
                  }
                  SuppressWarnings
                  FromTime
                  ToTime
                  ViewModes {
                    Value
                    Text
                    Selected
                  }
                }
                PrinterTimeInStatus{
                  StatusList {
                    State
                    StartTime
                    EndTime
                  }
                  FromTime
                  ToTime
                }
                StatusMessage
                StatusSnapshot {
                  EventTypeFilter
                  EventZoomTargetDate
                  FromTime
                  ToTime
                  Snapshot {
                    FieldName
                    FieldValue
                    NormalValue
                    Unit
                    OutOfSpec
                    NormalValue
                    MaxValue
                    MinValue
                    Default
                  }
                  Events {
                    DateTime
                    EventName
                    EventId
                    EventType
                  }
                  EventTypeList {
                    Selected
                    Text
                    Value
                  }
                }
                ApplicatorType
              }
            }
        `,
    };

    return invokeAppSync({
      gatewayName: "VRS_APPSYNC_DEVICE",
      path: baseAppSyncUrl(),
      method: "POST",
      body: graphQLBody,
      retryInTimeOut: true,
    });
  }

  static getAppSyncDataTagValues(deviceId: string) {
    if (!checkAppSyncUrlExists()) {
      return Promise.resolve({});
    }

    const graphQLBody = {
      query: `
          query DataTagValuesQuery{
            dataTagValues(deviceId: "${deviceId}") {
                  FieldName
                  FieldValue
                  OutOfSpec
                  Unit
                  Locations
                  BaseValueType
                  DecimalPlaces
                  Default
                }
            }
        `,
    };

    return invokeAppSync({
      gatewayName: "VRS_APPSYNC_DEVICE",
      path: baseAppSyncUrl(),
      method: "POST",
      body: graphQLBody,
      retryInTimeOut: true,
    });
  }

  static getAppSyncDataTagSimpleValues(deviceId: string) {
    if (!checkAppSyncUrlExists()) {
      return Promise.resolve({});
    }

    const graphQLBody = {
      query: `
          query DataTagValuesSimpleQuery{
            dataTagValues(deviceId: "${deviceId}") {
                  FieldName
                  BaseValue
                  BaseMinValue
                  BaseMaxValue
                  BaseValueType
                  Locations
                  Default
                }
            }
        `,
    };

    return invokeAppSync({
      gatewayName: "VRS_APPSYNC_DEVICE",
      path: baseAppSyncUrl(),
      method: "POST",
      body: graphQLBody,
      retryInTimeOut: true,
    });
  }

  static getAppSyncDataTagDetailValues(deviceId: string, dataTagNames: string[]) {
    if (!checkAppSyncUrlExists()) {
      return Promise.resolve({});
    }


    const graphQLBody = {
      query: `
          query DataTagValuesQuery{
            dataTagValues(deviceId: "${deviceId}", dataTagNames: ${JSON.stringify(dataTagNames)}) {
                FieldName
                FieldValue
                OutOfSpec
                Unit
                Locations
                MinValue
                MaxValue
                BaseValue
                BaseMinValue
                BaseMaxValue
                DecimalPlaces
                BaseValueType
                BaseValueSpecialType
                Cadence
                BaseValueUnits
                ImperialUnits
                MetricUnits
                ImperialValue
                MetricValue
                Default
              }
            }
        `,
    };

    return invokeAppSync({
      gatewayName: "VRS_APPSYNC_DEVICE",
      path: baseAppSyncUrl(),
      method: "POST",
      body: graphQLBody,
      retryInTimeOut: true,
    });
  }

  static getAppSyncDeviceTimeInStatus(
    deviceId: string,
    eventHistoryFilter: IEventHistoryFilter
  ) {
    if (!checkAppSyncUrlExists()) {
      return Promise.resolve({});
    }

    const eventHistoryModelInput =
      VrsDeviceApi.createEventHistoryModelInput(eventHistoryFilter);
 
    const graphQLBody = {
      query: `
          query TimeInStatusQuery {
            device(id: "${deviceId}", ${eventHistoryModelInput}) {
              PrinterTimeInStatus {
                StatusList {
                  State
                  StartTime
                  EndTime
                }
                FromTime
                ToTime
              }
            }
          }
        `,
    };

    return invokeAppSync({
      gatewayName: "VRS_APPSYNC_DEVICE",
      path: baseAppSyncUrl(),
      method: "POST",
      body: graphQLBody,
      retryInTimeOut: true,
    });
  }

  static getAppSyncDeviceEventHistory(
    deviceId: string,
    eventHistoryFilter: IEventHistoryFilter
  ) {
    if (!checkAppSyncUrlExists()) {
      return Promise.resolve({});
    }

    const eventHistoryModelInput =
      VrsDeviceApi.createEventHistoryModelInput(eventHistoryFilter);

    const graphQLBody = {
      query: `
          query EventHistoryQuery {
            device(id: "${deviceId}", ${eventHistoryModelInput}) {
              EventHistory {
                EventTypeFilter
                EventZoomTargetDate
                Sort
                Results {
                  EventId
                  DateTime
                  UtcDateTime
                  EventType
                  EventName
                  DateString
                  TestResult
                  EventCodeId
                  SalesForceArticleLink
                  ArticleLink
                  ArticleId
                  ActiveArticleLink
                }
                Stats {
                  EventType
                  EventsList {
                    EventCount
                    EventId
                    EventName
                    HasEvents
                  }
                  TypeId
                }
                SuppressWarnings
                FromTime
                ToTime
                ViewModes {
                  Value
                  Text
                  Selected
                }
              }
            }
          }
        `,
    };

    return invokeAppSync({
      gatewayName: "VRS_APPSYNC_DEVICE",
      path: baseAppSyncUrl(),
      method: "POST",
      body: graphQLBody,
      retryInTimeOut: true,
    });
  }

  static getAppSyncDeviceParameterHistory(
    deviceId: string,
    eventHistoryFilter: IEventHistoryFilter
  ) {
    if (!checkAppSyncUrlExists()) {
      return Promise.resolve({});
    }

    const eventHistoryModelInput =
      VrsDeviceApi.createEventHistoryModelInput(eventHistoryFilter);

    const graphQLBody = {
      query: `
          query ParameterHistoryQuery {
            device(id: "${deviceId}", ${eventHistoryModelInput}) {
              ParameterHistory
            }
          }
        `,
    };

    return invokeAppSync({
      gatewayName: "VRS_APPSYNC_DEVICE",
      path: baseAppSyncUrl(),
      method: "POST",
      body: graphQLBody,
      retryInTimeOut: true,
    });
  }

  static getAppSyncDeviceEvents(
    deviceId: string,
    snapshotFilter: ISnapshotFilter
  ) {
    if (!checkAppSyncUrlExists()) {
      return Promise.resolve({});
    }

    const statusSnapshotModelInput =
      VrsDeviceApi.createStatusSnapshotModelInput(snapshotFilter);

    const graphQLBody = {
      query: `
          query SnapshotEventQuery {
            device(id: "${deviceId}", ${statusSnapshotModelInput}) {
              StatusSnapshot {
                EventTypeFilter
                EventZoomTargetDate
                FromTime
                ToTime
                Events {
                  DateTime
                  EventName
                  EventId
                  EventType
                }
                EventTypeList {
                  Selected
                  Text
                  Value
                }
              }
            }
          }
        `,
    };

    return invokeAppSync({
      gatewayName: "VRS_APPSYNC_DEVICE",
      path: baseAppSyncUrl(),
      method: "POST",
      body: graphQLBody,
      retryInTimeOut: true,
    });
  }

  static getAppSyncDevices(
    companyId: string,
    siteId: string,
    timeRange: ITimeRange,
    serialNumber: string,
  ) {
    if (!checkAppSyncUrlExists()) {
      return Promise.resolve({});
    }

    const graphQLBody = {
      query: `
        query DevicesQuery {
          devices(companyId: "${companyId}", siteId: "${siteId}", timeRange: {value:"${timeRange.value
        }", start:"${timeRange.start
          ? Utils.evaluateAsUtc(moment(timeRange.start), true)
          : ""
        }", end:"${timeRange.end ? Utils.evaluateAsUtc(moment(timeRange.end), false) : ""
        }"}, serialNumber: "${serialNumber}") {
            ConsumablesLevel {
              Core
              CoreLevel
              CoreLevelPercent
              CoreRemaining
              InkLevel
              LastUpdate
              MakeupLevel
              ServiceModule1PredictedExpirationDate
              ServiceModuleLifetime
              TotalJetOnTime
            }
            StatusMessage
            LastStatusMessages {
              MessageName 
              MessageDate 
              MessageType 
            }
            Status
            StatusTypeID
            SerialNumber
            IsCellular
            PlantID
            CompanyID
            PrinterID
            PrinterIpAddress
            PlantName
            ModelName
            ModelType
            ModelId
            FriendlyName
            DurationSec
            CurrentJob
          }
        }
      `,
    };

    return invokeAppSync({
      gatewayName: "VRS_APPSYNC_DEVICE",
      path: baseAppSyncUrl(),
      method: "POST",
      body: graphQLBody,
      retryInTimeOut: true,
    }).then((results) => {
      if (results.data?.devices) {
        return {
          error: null,
          data: results.data.devices.map((el) => ({
            id: ignoreEmpty(el.PrinterID ? el.PrinterID.toLowerCase() : ""),
            isCellular: ignoreEmpty(el.IsCellular),
            plantId: ignoreEmpty(el.PlantID),
            companyId: ignoreEmpty(el.CompanyID),
            plant: ignoreEmpty(el.PlantName),
            model: ignoreEmpty(el.ModelName),
            modelType: el.ModelType,
            modelId: el.ModelId,
            printerName: ignoreEmpty(el.FriendlyName),
            printerSerialNumber: ignoreEmpty(el.SerialNumber),
            currentStatus: ignoreEmpty(el.Status),
            statusTypeID: ignoreEmpty(el.StatusTypeID),
            timeInStatus: ignoreEmpty(el.DurationSec),
            lastMessage: ignoreEmpty(el.StatusMessage),
            lastStatusMessages: ignoreEmpty(el.LastStatusMessages),
            printerIpAddress: ignoreEmpty(el.PrinterIpAddress),
            faultCount: ignoreEmpty(el.FaultCount),
            ConsumablesLevel: el.ConsumablesLevel,
            makeUp: convertPercent(
              ignoreMoreThan100(
                ignoreNegative(
                  convertStrangeChars(
                    ignoreEmpty(el.ConsumablesLevel.MakeupLevel)
                  )
                )
              )
            ),
            inkLevel: convertPercent(
              ignoreMoreThan100(
                ignoreNegative(
                  convertStrangeChars(ignoreEmpty(el.ConsumablesLevel.InkLevel))
                )
              )
            ),
            inkCoreRemaining: ignoreEmpty(el.ConsumablesLevel.CoreRemaining),
            jobName: ignoreEmpty(el.CurrentJob),
            jobCount: ignoreNegative(ignoreEmpty(el.JobCount)),
            upTime:
              el.PercentAvailable !== null
                ? convertPercent(
                  ignoreMoreThan100(
                    ignoreNegative(
                      convertStrangeChars(ignoreEmpty(el.PercentAvailable))
                    )
                  )
                )
                : null,
          })),
        };
      }

      return {
        error:
          results.errors && results.errors.length > 0
            ? results.errors[0]
            : results.errors,
        data: null,
      };
    });
  }

  static getAppSyncDevices2(
    companyId: string,
    siteId: string,
    timeRange: ITimeRange,
    serialNumber: string
  ) {
    if (!checkAppSyncUrlExists()) {
      return Promise.resolve({});
    }


    const graphQLBody = {
      query: `
        query DevicesQuery {
          devices(companyId: "${companyId}", siteId: "${siteId}", timeRange: {value:"${timeRange.value
        }", start:"${timeRange.start
          ? Utils.evaluateAsUtc(moment(timeRange.start), true)
          : ""
        }", end:"${timeRange.end ? Utils.evaluateAsUtc(moment(timeRange.end), false) : ""
        }"}, serialNumber: "${serialNumber}") {
            ConsumablesLevel {
              Core
              CoreLevel
              CoreLevelPercent
              CoreRemaining
              InkLevel
              LastUpdate
              MakeupLevel
              ServiceModule1PredictedExpirationDate
              ServiceModuleLifetime
              TotalJetOnTime
            }
            StatusMessage
            LastStatusMessages {
              MessageName 
              MessageDate 
              MessageType 
            }
            Status
            StatusTypeID
            SerialNumber
            IsCellular
            PlantID
            CompanyID
            PrinterID
            PrinterIpAddress
            PlantName
            ModelName
            ModelType
            ModelId
            FriendlyName
            DurationSec
            CurrentJob
          }
        }
      `,
    };

    return invokeAppSync({
      gatewayName: "VRS_APPSYNC_DEVICE",
      path: baseAppSyncUrl(),
      method: "POST",
      body: graphQLBody,
      retryInTimeOut: true,
    }).then((results) => {
      if (results.data?.devices) {
        const devices = results?.data?.devices.map((el) => ({
          id: ignoreEmpty(el.PrinterID ? el.PrinterID.toLowerCase() : ""),
          isCellular: ignoreEmpty(el.IsCellular),
          plantId: ignoreEmpty(el.PlantID),
          companyId: ignoreEmpty(el.CompanyID),
          plant: ignoreEmpty(el.PlantName),
          model: ignoreEmpty(el.ModelName),
          modelType: el.ModelType,
          modelId: el.ModelId,
          printerName: ignoreEmpty(el.FriendlyName),
          printerSerialNumber: ignoreEmpty(el.SerialNumber),
          currentStatus: ignoreEmpty(el.Status),
          statusTypeID: ignoreEmpty(el.StatusTypeID),
          timeInStatus: ignoreEmpty(el.DurationSec),
          lastMessage: ignoreEmpty(el.StatusMessage),
          lastStatusMessages: ignoreEmpty(el.LastStatusMessages),
          printerIpAddress: ignoreEmpty(el.PrinterIpAddress),
          faultCount: ignoreEmpty(el.FaultCount),
          ConsumablesLevel: el.ConsumablesLevel,
          makeUp: convertPercent(
            ignoreMoreThan100(
              ignoreNegative(
                convertStrangeChars(
                  ignoreEmpty(el.ConsumablesLevel.MakeupLevel)
                )
              )
            )
          ),
          inkLevel: convertPercent(
            ignoreMoreThan100(
              ignoreNegative(
                convertStrangeChars(ignoreEmpty(el.ConsumablesLevel.InkLevel))
              )
            )
          ),
          inkCoreRemaining: ignoreEmpty(el.ConsumablesLevel.CoreRemaining),
          jobName: ignoreEmpty(el.CurrentJob),
          jobCount: ignoreNegative(ignoreEmpty(el.JobCount)),
          upTime:
            el.PercentAvailable !== null
              ? convertPercent(
                ignoreMoreThan100(
                  ignoreNegative(
                    convertStrangeChars(ignoreEmpty(el.PercentAvailable))
                  )
                )
              )
              : null,
        }));

        return devices;
      }

      const error = results.errors && results.errors.length > 0 ? results.errors[0] : results.errors;
      if (error) {
        const errStr = extractErrors(error);
        if (
          errStr &&
          (errStr.indexOf("TooLarge") > -1 ||
            errStr.indexOf("size exceeded maximum") > -1 ||
            errStr.indexOf("Execution timed out") > -1)
        ) {
          throw new Error("There is too much data to download for the selected time range.  Please select a smaller time range.");
        } else {
          throw new Error(errStr || "Devices cannot be loaded!");
        }
      } else {
        throw new Error("Devices cannot be loaded!");
      }
    });
  }

  static getAppSyncDevicesTimeRangeData(
    companyId: string,
    siteId: string,
    timeRange: ITimeRange,
    serialNumber: string,
  ) {
    if (!checkAppSyncUrlExists()) {
      return Promise.resolve({});
    }

    const graphQLBody = {
      query: `
        query DevicesTimeRangeDataQuery {
          devices(companyId: "${companyId}", siteId: "${siteId}", timeRange: {value:"${timeRange.value
        }", start:"${timeRange.start
          ? Utils.evaluateAsUtc(moment(timeRange.start), true)
          : ""
        }", end:"${timeRange.end ? Utils.evaluateAsUtc(moment(timeRange.end), false) : ""
        }"}, serialNumber: "${serialNumber}") {
            PrinterID
            JobCount
            FaultCount
            PercentAvailable
          }
        }
      `,
    };

    return invokeAppSync({
      gatewayName: "VRS_APPSYNC_DEVICE",
      path: baseAppSyncUrl(),
      method: "POST",
      body: graphQLBody,
      retryInTimeOut: true,
    }).then((results) => {
      if (results.data?.devices) {
        return {
          error: null,
          data: results.data.devices.map((el) => ({
            id: ignoreEmpty(el.PrinterID ? el.PrinterID.toLowerCase() : ""),
            jobCount: ignoreNegative(ignoreEmpty(el.JobCount)),
            faultCount: ignoreEmpty(el.FaultCount),
            upTime:
              el.PercentAvailable !== null
                ? convertPercent(
                  ignoreMoreThan100(
                    ignoreNegative(
                      convertStrangeChars(ignoreEmpty(el.PercentAvailable))
                    )
                  )
                )
                : null,
          })),
        };
      }

      return {
        error:
          results.errors && results.errors.length > 0
            ? results.errors[0]
            : results.errors,
        data: null,
      };
    });
  }
  static getAppSyncDevicesTimeRangeData2(
    companyId: string,
    siteId: string,
    timeRange: ITimeRange,
    serialNumber: string
  ) {
    if (!checkAppSyncUrlExists()) {
      return Promise.resolve({});
    }

    const graphQLBody = {
      query: `
        query DevicesTimeRangeDataQuery {
          devices(companyId: "${companyId}", siteId: "${siteId}", timeRange: {value:"${timeRange.value
        }", start:"${timeRange.start
          ? Utils.evaluateAsUtc(moment(timeRange.start), true)
          : ""
        }", end:"${timeRange.end ? Utils.evaluateAsUtc(moment(timeRange.end), false) : ""
        }"}, serialNumber: "${serialNumber}") {
            PrinterID
            JobCount
            FaultCount
            PercentAvailable
          }
        }
      `,
    };

    return invokeAppSync({
      gatewayName: "VRS_APPSYNC_DEVICE",
      path: baseAppSyncUrl(),
      method: "POST",
      body: graphQLBody,
      retryInTimeOut: true,
    }).then((results) => {
      if (results.data?.devices) {
        const devices = results.data.devices.map((el) => ({
          id: ignoreEmpty(el.PrinterID ? el.PrinterID.toLowerCase() : ""),
          jobCount: ignoreNegative(ignoreEmpty(Number(el.JobCount.replace(/,/g, '')))),
          faultCount: ignoreEmpty(el.FaultCount),
          upTime:
            el.PercentAvailable !== null
              ? convertPercent(
                ignoreMoreThan100(
                  ignoreNegative(
                    convertStrangeChars(ignoreEmpty(el.PercentAvailable))
                  )
                )
              )
              : null,
        }));

        return devices;
      }

      throw new Error("Devices Time Range datacannot be loaded!");
    });
  }

  static getAppSyncDevicesSimple(
    companyId: string,
    siteId: string,
    timeRange: ITimeRange,
    serialNumber: string,
  ) {
    if (!checkAppSyncUrlExists()) {
      return Promise.resolve({});
    }


    const graphQLBody = {
      query: `
        query SimpleDevicesQuery {
          devices(companyId: "${companyId}", siteId: "${siteId}", timeRange: {value:"${timeRange.value
        }", start:"${timeRange.start
          ? Utils.evaluateAsUtc(moment(timeRange.start), true)
          : ""
        }", end:"${timeRange.end ? Utils.evaluateAsUtc(moment(timeRange.end), false) : ""
        }"}, serialNumber: "${serialNumber}") {
            SerialNumber
            IsCellular
            PlantID
            CompanyID
            PrinterID
            PrinterIpAddress
            PlantName
            PercentAvailable
            ModelName
            ModelType
            ModelId
            FriendlyName
          }
        }
      `,
    };

    return invokeAppSync({
      gatewayName: "VRS_APPSYNC_DEVICE",
      path: baseAppSyncUrl(),
      method: "POST",
      body: graphQLBody,
      retryInTimeOut: true,
    }).then((results) => {
      if (results.data?.devices) {
        return results.data.devices.map((el) => ({
          id: ignoreEmpty(el.PrinterID ? el.PrinterID.toLowerCase() : ""),
          isCellular: ignoreEmpty(el.IsCellular),
          plantId: ignoreEmpty(el.PlantID),
          companyId: ignoreEmpty(el.CompanyID),
          plant: ignoreEmpty(el.PlantName),
          model: ignoreEmpty(el.ModelName),
          modelType: el.ModelType,
          modelId: el.ModelId,
          printerName: ignoreEmpty(el.FriendlyName),
          printerSerialNumber: ignoreEmpty(el.SerialNumber),
          currentStatus: ignoreEmpty(el.Status),
          statusTypeID: ignoreEmpty(el.StatusTypeID),
          timeInStatus: ignoreEmpty(el.DurationSec),
          lastMessage: ignoreEmpty(el.StatusMessage),
          lastStatusMessages: ignoreEmpty(el.LastStatusMessages),
          printerIpAddress: ignoreEmpty(el.PrinterIpAddress),
        }));
      }
    });
  }

  static getAppSyncTrend(
    printerId: string,
    trendName: string,
    snapshotFilter: ISnapshotFilter,
    statusChartType: string
  ) {
    if (!checkAppSyncUrlExists()) {
      return Promise.resolve({});
    }


    const startEndObj = Utils.getStartAndEndDate(snapshotFilter.timeRange);
    const graphQLBody = {
      query: `
      query TrendQuery {
        trend(fromTime: "${snapshotFilter.timeRange.value === "custom" &&
          snapshotFilter.timeRange.start
          ? Utils.evaluateAsUtc(moment(snapshotFilter.timeRange.start), true)
          : Utils.convertToUtc(moment(startEndObj.start))
        }",
          toTime: "${snapshotFilter.timeRange.value === "custom" &&
          snapshotFilter.timeRange.end
          ? Utils.evaluateAsUtc(moment(snapshotFilter.timeRange.end), true)
          : Utils.convertToUtc(moment(startEndObj.end))
        }",
          printerId: "${printerId}",
          statusChartType: "${statusChartType}",
          trendName: "${trendName}" ) {
            Act
            Dt
        }
      }
      `,
    };

    return invokeAppSync({
      gatewayName: "VRS_APPSYNC_REPORT",
      path: baseAppSyncUrl(),
      method: "POST",
      body: graphQLBody,
      retryInTimeOut: true,
    }).then((results) => {
      if (results.data?.trend) {
        return {
          error: null,
          data: results.data.trend.map((el) => ({
            actual: el.Act,
            dateTimeStamp: el.Dt,
            value: 0,
          })),
        };
      }

      return {
        error:
          results.errors && results.errors.length > 0
            ? results.errors[0]
            : results.errors,
        data: null,
      };
    });
  }

  static getAppSyncMiniDataDownload(
    printerId: string,
    eventDate: string,
    siteId: string,
    serialNumber: string,
    eventId: string
  ) {
    if (!checkAppSyncUrlExists()) {
      return Promise.resolve({});
    }


    const graphQLBody = {
      query: `
        query MiniDownloadQuery {
          printerMiniDownload(printerId: "${printerId}", eventDate: "${eventDate}", siteId: "${siteId}", serialNumber: "${serialNumber}", eventId: "${eventId}") {
            Items {
              Category
              DataType
              DateTimeStamp
              LocalPrinterTime
              MaxValue
              MessageName
              MessageType
              MinValue
              ModelName
              NormalValue
              OutOfSpec
              Value
            }
          }
        }
      `,
    };

    return invokeAppSync({
      gatewayName: "VRS_APPSYNC_REPORT",
      path: baseAppSyncUrl(),
      method: "POST",
      body: graphQLBody,
      retryInTimeOut: true,
    }).then((results) => {
      if (results.data?.printerMiniDownload?.Items) {
        return {
          items: results.data.printerMiniDownload.Items.map((el) => ({
            category: el.Category,
            dataType: el.DataType,
            dateTimeStamp: el.DateTimeStamp,
            localPrinterTime: el.LocalPrinterTime,
            maxValue: el.MaxValue,
            messageName: el.MessageName,
            messageType: el.MessageType,
            minValue: el.MinValue,
            modelName: el.ModelName,
            normalValue: el.NormalValue,
            outOfSpec: el.OutOfSpec,
            value: el.Value,
          })),
        };
      }
    });
  }

  static getAppSyncServiceLogList(
    printerId: string
  ) {
    if (!checkAppSyncUrlExists()) {
      return Promise.resolve({});
    }

    const graphQLBody = {
      query: `
        query ServiceLogQuery{
          serviceLogs(printerId: "${printerId}"){
            ServiceLogId
            Description
            TimeStamp
            Title
            Status
            Username
          }
        }
      `,
    };

    return invokeAppSync({
      gatewayName: "VRS_APPSYNC_DEVICE",
      path: baseAppSyncUrl(),
      method: "POST",
      body: graphQLBody,
    }).then((results) => {
      if (results.data?.serviceLogs) {
        return results.data;
      }
    });
  }

  static AppSyncAddServiceLogEntry(
    printerId: string,
    status: string,
    description: string,
    title: string,
  ) {
    if (!checkAppSyncUrlExists()) {
      return Promise.resolve({});
    }

    const graphQLBody = {
      query: `
        mutation AddServiceLogQuery{
          addServiceLog(serviceLogInput: {
            Description: "${description}"
            Status: "${status}"
            Title: "${title}"
          },printerId: "${printerId}"){
            ServiceLogId
            Description
            TimeStamp
            Title
            Status
            Username
          }
        }
      `,
    };

    return invokeAppSync({
      gatewayName: "VRS_APPSYNC_DEVICE",
      path: baseAppSyncUrl(),
      method: "POST",
      body: graphQLBody,
    }).then((results) => {
      if (results.data?.addServiceLog) {
        return results.data;
      }
    });
  }

  static AppSyncUpdateServiceLogEntry(
    printerId: string,
    serviceLogInput: IServiceLogInput,
  ) {
    if (!checkAppSyncUrlExists()) {
      return Promise.resolve({});
    }

    const graphQLBody = {
      query: `
        mutation UpdateServiceLogQuery{
          updateServiceLog(serviceLogInput: {
            ServiceLogId: "${serviceLogInput.ServiceLogId}"
            Status: "${serviceLogInput.Status ? serviceLogInput.Status : ''}"
            IsDeleted: ${serviceLogInput.IsDeleted ? serviceLogInput.IsDeleted : null}
          },printerId: "${printerId}"){
            ServiceLogId
            Description
            TimeStamp
            Title
            Status
            Username
          }
        }
      `,
    };
    return invokeAppSync({
      gatewayName: "VRS_APPSYNC_DEVICE",
      path: baseAppSyncUrl(),
      method: "POST",
      body: graphQLBody,
    }).then((results) => {
      if (results.data?.updateServiceLog) {
        return results.data;
      }
    });
  }

  static getAppSyncDiagnosticLogDownload(
    printerId: string,
    eventDate: string,
    siteId: string,
    serialNumber: string,
    eventId: string
  ) {
    if (!checkAppSyncUrlExists()) {
      return Promise.resolve({});
    }

    const graphQLBody = {
      query: `
        query MiniDownloadQuery {
          printerMiniDownload(printerId: "${printerId}", eventDate: "${eventDate}", siteId: "${siteId}", serialNumber: "${serialNumber}", eventId: "${eventId}") {
            FaultDiagnosticLog
          }
        }
      `,
    };

    return invokeAppSync({
      gatewayName: "VRS_APPSYNC_REPORT",
      path: baseAppSyncUrl(),
      method: "POST",
      body: graphQLBody,
    }).then((results) => {
      if (results.data?.printerMiniDownload) {
        return {
          faultDiagnosticLog:
            results.data.printerMiniDownload.FaultDiagnosticLog,
        };
      }
    });
  }

  static checkForAppSyncDiagnosticLogDownload(
    printerId: string,
    eventDate: string,
    siteId: string,
    serialNumber: string,
    eventId: string
  ) {
    if (!checkAppSyncUrlExists()) {
      return Promise.resolve({});
    }

    const graphQLBody = {
      query: `
        query PrinterLogExistsQuery {
          printerLogFileForEventExists(printerId: "${printerId}", eventDate: "${eventDate}", siteId: "${siteId}", serialNumber: "${serialNumber}", eventId: "${eventId}") 
        }
      `,
    };

    return invokeAppSync({
      gatewayName: "VRS_APPSYNC_REPORT",
      path: baseAppSyncUrl(),
      method: "POST",
      body: graphQLBody,
    }).then((results) => {
      if (results.data?.printerLogFileForEventExists) {
        return results.data.printerLogFileForEventExists;
      }
    });
  }

  static getAppSyncPrinterTestResult(
    printerId: string,
    utcDateTime: string,
    eventCodeId: string,
    eventId: string
  ) {
    if (!checkAppSyncUrlExists()) {
      return Promise.resolve({});
    }

    const graphQLBody = {
      query: `
        query PrinterTestResultQuery {
          getPrinterTestResult(eventCodeId: ${eventCodeId}, printerId: "${printerId}", utcDateTime: "${utcDateTime}", eventId: ${eventId}) {
            EventCodeId
            EventCodeName
            ModelName
            PrinterId
            SerialNumber
            UtcDateTime
            TestResults {
              Evaluation
              ResultOutput
              ResultValue
              TestName
              TestParameter
              ToolVersion
              TestPassed
              Weight
            }
          }
        }
      `,
    };

    return invokeAppSync({
      gatewayName: "VRS_APPSYNC_REPORT",
      path: baseAppSyncUrl(),
      method: "POST",
      body: graphQLBody,
    }).then((results) => {
      if (results.data?.getPrinterTestResult) {
        return results.data.getPrinterTestResult;
      }
    });
  }

  static searchPlantAppSync(searchTerm: string) {
    if (!checkAppSyncUrlExists()) {
      return Promise.resolve({});
    }

    const graphQLBody = {
      query: `
          query SearchPlantQuery {
            searchPlant(searchTerm: "${searchTerm}") {
              CompanyId
              CompanyName
              PlantId
              PlantName
              CountryId
              SupportPhoneNumber
              ShipToSiteId
            }
          }
        `,
    };

    return invokeAppSync({
      gatewayName: "VRS_APPSYNC_SITE",
      path: baseAppSyncUrl(),
      method: "POST",
      body: graphQLBody,
    }).then((results) => {
      if (results.data?.searchPlant) {
        const plants: Array<IPlantSearchResult> =
          results?.data?.searchPlant.map((el) => ({
            companyId: el.CompanyId.toString(),
            companyName: el.CompanyName,
            plantId: el.PlantId.toString(),
            plantName: el.PlantName,
            supportPhoneNumber: el.SupportPhoneNumber || "",
            countryId:
              el.CountryId || el.CountryId === 0 ? el.CountryId.toString() : "",
            shipToSiteId: el.ShipToSiteId || "",
          }));
        return plants;
      }
      return [];
    });
  }

  static askSupport(askSupportInput: ISupportRequestObj) {
    if (!checkAppSyncUrlExists()) {
      return Promise.resolve({
        error: null,
        data: null,
      });
    }

    const graphQLBody = {
      query: `
      mutation AskSupportMutation {
        askSupport(askSupportInput: {
          EmailAddress: "${escapeCharacters(askSupportInput.EmailAddress)}"
          Message: "${escapeCharacters(askSupportInput.Message)}"
          PhoneNumber: "${askSupportInput.PhoneNumber
          ? escapeCharacters(askSupportInput?.PhoneNumber)
          : askSupportInput.PhoneNumber
        }"
          Name: "${escapeCharacters(askSupportInput.Name)}"
          PlantId: "${askSupportInput.PlantId}"
          PrinterId: "${askSupportInput.PrinterId}"
          PrinterFriendlyName: "${escapeCharacters(
          askSupportInput.PrinterFriendlyName
        )}"
      }) {
          Result
        }
      }
        `,
    };

    return invokeAppSync({
      gatewayName: "VRS_APPSYNC_DEVICE",
      path: baseAppSyncUrl(),
      method: "POST",
      body: graphQLBody,
    }).then((results) => {
      if (results.data?.askSupport) {
        return {
          error: null,
          data: results.data?.askSupport,
        };
      }

      return {
        error:
          results.errors && results.errors.length > 0
            ? results.errors[0]
            : results.errors,
        data: null,
      };
    });
  }
}

export default VrsDeviceApi;
