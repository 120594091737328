import { useCallback, useEffect, useMemo, useState } from "react";
import StyledGridCell from "../../../../components/Basic/StyledGridCell/StyledGridCell";
import { useSiteSelectionComponentStyles } from "./SiteSelectionComponent.css";
import { useMediaQuery, useTheme, FormControl, Grid } from "@mui/material";
import { SearchableSelector } from "../../../../components/SearchableSelector/SearchableSelector";
import { CompanySelector } from "../../../../components/CompanySelector/CompanySelector";
import {
  useAppCompanyState,
  useAppSiteState,
  useVrsTranslationState,
} from "../../../../context/AppContext/AppContext";
import { useCompanyActions } from "../../../../actions/companyActions";
import { useSiteActions } from "../../../../actions/siteActions";
import authLib from "../../../../libs/authLib";
import { useConfigActions } from "../../../../actions/configActions";

const gridBreak: any = {
  company: { sm: 4, xs: 12 },
  site: { sm: 4, xs: 12 },
  printer: { sm: 4, xs: 12 },
};
interface SiteSelectionProps {
  selectedPrinterId: string;
  performSetSelectedPrinterId: any;
  performSetSelectedLogType: any;
  devices: Array<any>;
  printerModelName: string;
  fileTypes: any[];
}

const SiteSelectionComponent = ({
  selectedPrinterId,
  performSetSelectedPrinterId,
  devices,
  printerModelName,
}: SiteSelectionProps) => {
  const classes = useSiteSelectionComponentStyles();
  const companyActions = useCompanyActions();
  const siteState = useAppSiteState();
  const siteActions = useSiteActions();
  const { _T } = useVrsTranslationState();
  const configActions = useConfigActions();

  const { sites } = siteState;
  const {
    selectedCompanyId,
    selectedSiteIdForCompany,
    companies,
    companySites,
    companyInitiated,
    companyLoaded,
  } = useAppCompanyState();

  const [companyId, setCompanyId] = useState<string>("");
  const [siteName, setSiteName] = useState<string>("");
  const [printerName, setPrinterName] = useState<string>("");
  const [, setPrinterValue] = useState<string>("");
  const [siteOptions, setSiteOptions] = useState<Array<any>>([]);

  const [isCompanyDataLoading, setIsCompanyDataLoading] =
    useState<boolean>(false);

  const theme = useTheme();
  const extraSmallSize = useMediaQuery(theme.breakpoints.only("xs"));

  useEffect(() => {
    const activeCompanyId = selectedCompanyId === "0" ? "" : selectedCompanyId;
    setCompanyId(activeCompanyId);
  }, [selectedCompanyId]);

  useEffect(() => {
    const activeSiteId =
      selectedSiteIdForCompany === "0" ? "" : selectedSiteIdForCompany;
    const selectedSite =
      companySites && companySites.find((e) => e.id === activeSiteId);
    setSiteName(selectedSite ? selectedSite.name : activeSiteId);
  }, [companySites, selectedSiteIdForCompany]);

  useEffect(() => {
    const selectedPrinter = printers.find((p) => p.value == selectedPrinterId);
    setPrinterName(selectedPrinter ? selectedPrinter.text : selectedPrinterId);
  }, [selectedPrinterId]);
  const printers = useMemo(() => {
    const printerIdLower = selectedPrinterId.toLowerCase();
    if (devices) {
      const printerList = devices
        .filter(
          (d) =>
            [1581, 1582, 3019, 3874, 1881, 1882, 1883, 2380, 1586, 1587, 1590, 1886, 1887, 1888, 1890
              , 3000, 3001, 3002, 3003, 3004, 3005, 3006, 3007, 3008, 3009, 3012, 3013, 3014
              , 3015, 3019, 3340, 3863, 3864, 3865, 3866, 3867, 3873, 3874, 3875, 3876, 3877
              , 3878, 3879, 3880, 3881, 3882, 3883, 3884, 3885, 3886, 3887, 3888, 3889, 3890
              , 3891, 3892, 3893, 3894, 3895, 3896, 3897, 3898, 3899, 3976, 3977, 3978, 3979
              , 3980, 3981, 3982, 3983, 3984, 3985, 3986, 3987, 3988, 3989, 3990, 3991, 3992
              , 3993, 3994, 3995, 3996, 3997, 3998, 3999, 3020, 3021].indexOf(
              d.modelId
            ) > -1
        )
        .map((el) => ({
          value: el.printerSerialNumber,
          text: `${el.printerName ? el.printerName : ""}${
            el.printerSerialNumber ? " (" + el.printerSerialNumber + ")" : ""
          }`,
        }));

      if (!printerList.find((el) => el.value === printerIdLower)) {
        printerList.unshift({ value: printerIdLower, text: printerModelName });
      }
      return printerList
        .filter((pr) => pr.value !== "")
        .sort((a, b) => (a.text > b.text ? 1 : -1));
    }
    return [];
  }, [devices, printerModelName, selectedPrinterId]);

  const performSiteChange = useCallback(
    (siteId) => {
      (async () => {
        const currentUser: any = await authLib.getCurrentUser();

        if (currentUser && siteId) {
          if (!sites.find((el) => el.id === siteId)) {
            const userSite = await siteActions.loadSingleSite(siteId);
            if (userSite) {
              configActions.setDataInitialization("firstSiteData", (state) => ({
                ...state,
                loaded: true,
              }));
            }
          }
          await siteActions.setSite(siteId, currentUser);
        }
      })();
    },
    [configActions, siteActions, sites]
  );

  const plantChangedHandler = useCallback(
    (plantId) => {
      const activePlantId = plantId || "0";
      changePrinterHandler("");
      companyActions.setVrsCompanySiteId(activePlantId);
      if (activePlantId !== "0") {
        performSiteChange(plantId);
      }
    },
    [companyActions, performSiteChange]
  );
  const changePrinterHandler = useCallback(
    (value) => {
      setPrinterValue(value);
      performSetSelectedPrinterId(value);
    },
    [performSetSelectedPrinterId]
  );

  const companyChangedHandler = useCallback(
    (companyId) => {
      const activeCompanyId = companyId || "0";
      companyActions.setVrsCompanyAndSiteId(activeCompanyId, "0");
      changePrinterHandler("");
      if (activeCompanyId !== "0") {
        companyActions.getPlantsAppSync(activeCompanyId).then((sites) => {
          if (sites && sites.length === 1) {
            plantChangedHandler(sites[0].id);
          }
        });
      } else {
        siteActions.resetSelectedSiteId();
        siteActions.selectSite("");
        siteActions.resetSiteData("");
        companyActions.resetCompanyPlants();
      }

      // Clear filter
      configActions.setBarFilter("deviceFilter", (s) => ({
        ...s,
        status: {
          fault: false,
          warning: false,
          online: false,
          offline: false,
          disconnected: false,
          unknown: false,
        },
      }));
    },
    [companyActions, configActions, siteActions, plantChangedHandler]
  );

  useEffect(() => {
    const siteOptionsObj: any = {};
    companySites &&
      companySites.forEach((site) => {
        const siteName = site.name;
        const siteKey = `site_${site.id}`;
        if (!siteOptionsObj[siteKey]) {
          siteOptionsObj[siteKey] = {
            text: siteName || site.id,
            value: site.id.toString(),
          };
        }
      });

    setSiteOptions(
      Object.keys(siteOptionsObj)
        .map((key) => siteOptionsObj[key])
        .sort((a, b) => (a.text > b.text ? 1 : a.text < b.text ? -1 : 0))
    );
  }, [companySites]);

  useEffect(() => {
    if (!companyInitiated && !companyLoaded && !isCompanyDataLoading) {
      configActions.setIsLoading(true);
      setIsCompanyDataLoading(true);
      companyActions
        .getCompaniesAppSync()
        .then(() => {
          configActions.setAppDataInitialization(
            "companiesInitialization",
            (state) => ({
              ...state,
              loaded: true,
            })
          );

          if (selectedCompanyId !== "0") {
            companyActions.getPlantsAppSync(selectedCompanyId).then((sites) => {
              if (sites && sites.length === 1) {
                plantChangedHandler(sites[0].id);
              }
            });
          }
        })
        .finally(() => {
          configActions.setIsLoading(false);
          setIsCompanyDataLoading(false);
        });
    }
  }, [
    companyActions,
    configActions,
    isCompanyDataLoading,
    companyInitiated,
    companyLoaded,
    selectedCompanyId,
    plantChangedHandler,
  ]);

  return (
    <Grid className={extraSmallSize ? classes.column : classes.row}>
      <StyledGridCell
        {...gridBreak.company}
        className={classes.filterContainer}
      >
        <FormControl
          variant="outlined"
          className={classes.formControl}
          size="small"
        >
          <CompanySelector
            companies={companies}
            companyId={companyId}
            onCompanyChanged={companyChangedHandler}
          />
        </FormControl>
      </StyledGridCell>
      <StyledGridCell {...gridBreak.site} className={classes.filterContainer}>
        <FormControl
          variant="outlined"
          className={classes.formControl}
          size="small"
        >
          <SearchableSelector
            fieldName="siteId"
            label={_T("Site")}
            placeholder={_T("Site")}
            options={siteOptions}
            value={siteName}
            onChange={plantChangedHandler}
            onReset={() => plantChangedHandler("")}
            disableDescription={true}
            isDisabled={!!(siteOptions && siteOptions.length <= 1)}
            textSize="small"
          />
        </FormControl>
      </StyledGridCell>
      <StyledGridCell
        {...gridBreak.printer}
        className={classes.filterContainer}
      >
        <FormControl
          variant="outlined"
          className={classes.formControl}
          size="small"
        >
          <SearchableSelector
            fieldName="text"
            label={_T("Device")}
            placeholder={_T("Device")}
            options={printers}
            isDisabled={!!(printers && printers.length < 1)}
            value={printerName}
            onChange={changePrinterHandler}
            onReset={() => changePrinterHandler("")}
            textSize="small"
          />
        </FormControl>
      </StyledGridCell>
      {/* <StyledGridCell
        {...gridBreak.site}
        className={classes.filterContainer}
      >
        <FormControl
          variant="outlined"
          className={classNames(classes.formControl, classes.logType)}
          size="small"
        >
          <StyledDropDown
            labelId="fileTypes"
            multiple={false}
            name="fileTypes"
            label={_T("Log Type")}
            placeholder={""}
            options={fileTypes}
            value={logType}
            onChange={changeLogTypeHandler}
            size="small"
          />
        </FormControl>
      </StyledGridCell> */}
    </Grid>
  );
};

export default SiteSelectionComponent;
